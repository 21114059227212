exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-editor-js": () => import("./../../../src/pages/editor.js" /* webpackChunkName: "component---src-pages-editor-js" */),
  "component---src-pages-grid-js": () => import("./../../../src/pages/grid.js" /* webpackChunkName: "component---src-pages-grid-js" */),
  "component---src-pages-min-reise-js": () => import("./../../../src/pages/min-reise/[...].js" /* webpackChunkName: "component---src-pages-min-reise-js" */),
  "component---src-pages-min-resa-js": () => import("./../../../src/pages/min-resa/[...].js" /* webpackChunkName: "component---src-pages-min-resa-js" */),
  "component---src-pages-minun-matkani-js": () => import("./../../../src/pages/minun-matkani/[...].js" /* webpackChunkName: "component---src-pages-minun-matkani-js" */),
  "component---src-templates-destination-page-js": () => import("./../../../src/templates/destination_page.js" /* webpackChunkName: "component---src-templates-destination-page-js" */),
  "component---src-templates-dynamic-js": () => import("./../../../src/templates/dynamic.js" /* webpackChunkName: "component---src-templates-dynamic-js" */),
  "component---src-templates-hotel-page-js": () => import("./../../../src/templates/hotel_page.js" /* webpackChunkName: "component---src-templates-hotel-page-js" */)
}

