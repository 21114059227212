import './src/styles/global.css';

/*export const wrapRootElement = ({ element }) => (
  <BookingContextProvider>{element}</BookingContextProvider>
);*/

function loadScript(src, id, onload) {
  if (document.getElementById(id)) {
    return;
  }

  var script = document.createElement('script');
  script.async = true;
  script.src = src;
  script.id = id;
  script.onload = onload;
  document.body.appendChild(script);
}

export const onRouteUpdate = ({ location, pageContext }) => {
  if (
    location.search.includes('_storyblok') &&
    location.search.includes('token')
  ) {
    loadScript(
      '//app.storyblok.com/f/storyblok-v2-latest.js',
      'storyblok-javascript-bridge-v2',
      () => {
        console.log('Storyblok Bridge has been loaded');
      },
    );
  }
};
